<template>
  <div></div>
</template>

<script>
import AWS from 'aws-sdk';
import { ENG } from '@/enum/language-type';

export default {
  name: 'TermsForm',
  props: {
    termsType: { type: String, required: true },
    version: { type: String, required: true },
    language: { type: String, required: true },
  },

  mounted() {
    // AWS config 업데이트
    AWS.config.update({
      region: process.env.VUE_APP_S3_BUCKET_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.VUE_APP_S3_IDENTITY_POOL_ID,
      }),
    });

    // S3 설정
    const s3 = new AWS.S3({
      apiVersion: process.env.VUE_APP_S3_API_VERSION,
      params: {
        Bucket: process.env.VUE_APP_S3_BUCKET_NAME,
      },
    });

    // props 로그 확인
    console.log('props', this.termsType, this.version, this.language);

    // S3 버킷리스트 가져오기
    const prefix = this.termsType + '/' + this.version;
    s3.listObjects({ Prefix: prefix }, (err, data) => {
      if (err) {
        return alert(err);
      } else {
        // 버킷리스트 로그 확인
        console.log('s3.listObjects', data);

        // prefix 데이터가 존재하지 않음 => 에러
        if (data.Contents.length === 0) {
          return alert('terms(' + prefix + ') data is not exists');
        }

        let termsPath = prefix + '/' + this.language + '/index.html';
        console.log('termsPath', termsPath);

        let idx = data.Contents.findIndex(content => content.Key === termsPath);

        // 해당 언어 타입이 없는 경우 default 언어인 영어로 다시 시도
        if (idx < 0) {
          console.log('terms(' + termsPath + ') data is not exists');

          termsPath = prefix + '/' + ENG + '/index.html';

          idx = data.Contents.findIndex(content => content.Key === termsPath);

          if (idx < 0) {
            return alert('terms(' + termsPath + ') data is not exists');
          }
        }

        // Terms 페이지 오픈
        const openUrl = process.env.VUE_APP_S3_BUCKET_URL + '/' + termsPath;
        console.log('openUrl', openUrl);

        window.location.href = openUrl;
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
